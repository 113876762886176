<template>
    <nav class="punch-card">
        <div class="item-container" v-if="list.length">
            <div v-for="(item, index) in list" :key="index">
                <router-link
                    :to="{
                        path: path || '/route/details',
                        query: { id: item.id },
                    }"
                >
                    <div class="item-card">
                        <van-image class="img" :src="item[imgTag]" fit="cover" radius="10 20" />
                        <div class="punch-content">
                            <figure>
                                <div class="punch-title">
                                    {{ item[txtTag] }}
                                </div>
                                <slot v-bind:item="item">
                                    <div class="punch-label">
                                        {{ item.lineType }}
                                    </div>
                                    <figcaption>
                                        <div>有效期：{{ item.lineTime }}</div>
                                        <div>任务：{{ item.lineTask }}</div>
                                    </figcaption>
                                </slot>
                            </figure>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "punch-card",
    props: ["list", "imgBaseUrl", "txtTag", "imgTag", "path"],
    components: {},
    data() {
        return {};
    },
    methods: {
        getCategoryId() {},
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_varibles";
@import "@/style/mixin";

.punch-card {
    background-color: #fff;
    .item-container {
        @include wh(100%, auto);
        padding: 0 36px;

        .item-card {
            @include fj(center);
            width: 100%;
            text-align: center;
            margin-top: 30px;
            border-radius: 20px;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
            .img {
                @include wh(100%, 234px);
                border-radius: 20px 0 0 20px;
            }
            .punch-content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 450px;
                text-align: left;
                padding-left: 30px;
                padding-right: 20px;
                figure {
                    width: 100%;
                    .punch-title {
                        font-weight: bold;
                        // margin-left: -50px;
                        @include sc(32px, $--color-text-regular);
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        /*! autoprefixer: off */
                        -webkit-box-orient: vertical;
                        /* autoprefixer: on */
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        word-break: break-all; /*追加这一行代码*/
                    }
                    .punch-label {
                        @include sc(24px, $--color-text-primary);
                    }
                    figcaption {
                        @include sc(20px, $--color-text-regular);
                        overflow-wrap: break-word;
                        white-space: nowrap;
                        line-height: 28px;
                        padding: 14px 12px 24px 24px;
                        white-space: normal;
                        text-align: left;
                    }
                }
            }
        }
    }
    .clock-info {
        @include sc(24px, $--color-text-secondary);
        .clock-label {
            line-height: 28px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            /*! autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            -webkit-line-clamp: 2;
            line-clamp: 2;
            word-break: break-all; /*追加这一行代码*/
        }
        .clock-button {
            @include wh(130px, 50px);
            @include sc(22px, $--color-text-primary);
            border-radius: 50px;
            text-align: center;
            line-height: 50px;
            border: 1px solid $--color-text-primary;
            background: #f5e3e4;
            margin-left: 30px;
        }
    }
}
</style>