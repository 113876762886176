<template>
    <div class="route-details">
        <div class="route-banner" @click="openPage">
            <van-image
                width="100%"
                height="100%"
                radius="0"
                :src="punchLine.indexUrl"
                fit="cover"
            />
        </div>
        <div class="route-content">
            <div class="route-title">{{ punchLine.lineTitle }}</div>

            <div class="u-m-t-20">
                <div class="introduction-header">
                    <div class="title">路线简介：</div>
                </div>
                <div
                    class="details-content"
                    v-html="punchLine.indexContent"
                ></div>
                <div class="introduction-header">
                    <div class="title">打卡点：</div>
                    <div class="more">
                        {{ punchLine.alreadyPoint }} /
                        {{ punchLine.lineTask }}
                    </div>
                </div>
                <div class="u-m-t-40">
                    <punch-card
                        class="u-m-t-30"
                        :list="punchLine.mllLinePoints || []"
                        txtTag="lpTitle"
                        imgTag="logoUrl"
                        path="/point/details"
                    >
                        <template v-slot:default="slotProps">
                            <div class="clock-info">
                                <div class="u-m-t-20 clock-label">
                                    {{ slotProps.item.lpAddress }}
                                </div>
                                <div class="clock-button u-m-t-20">
                                    {{ slotProps.item.lpTotal }}人打卡
                                </div>
                            </div>
                        </template>
                    </punch-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getLineDetails } from "@/service";
import punchCard from "./components/punch-card.vue";
export default {
    components: {
        punchCard,
    },
    data() {
        return {
            active: 2,
            detailHtml: "123123213",
            punchLine: {},
            positions: [],
            positionAddress: [],
        };
    },
    mounted() {
        const { id = "" } = this.$route.query;
        this.getLineDetails(id);
    },
    methods: {
        async getLineDetails(id) {
            const params = {
                lineId: id,
            };
            const { data } = await getLineDetails(params);
            this.punchLine = data;
            const positions = data.mllLinePoints.map((item) => [
                Number(item.lpLongitude),
                Number(item.lpLatitude),
            ]);
            const positionAddress = data.mllLinePoints.map(
                (item) => item.lpAddress
            );
            this.positions = positions;
            this.positionAddress = positionAddress;
            document.title = data.lineTitle;
        },
        openPage() {
            sessionStorage.setItem("positions", JSON.stringify(this.positions));
            sessionStorage.setItem(
                "address",
                JSON.stringify(this.positionAddress)
            );
            this.$nextTick(() => {
                this.$router.push("/maps");
            });
        },
    },
};
</script>

<style lang="scss">
@import "@/style/mixin";
@import "@/style/_varibles";

.route-details {
    .route-banner {
        width: 100%;
        height: 454px;
    }
    .route-content {
        padding: 20px 0 50px 0;
        .route-title {
            @include sc(32px, $--color-text-regular);
            font-weight: 900;
            line-height: 40px;
            padding-left: 38px;
        }
        .introduction-header {
            @include wh(100%, 60px);
            @include fj(space-between);
            padding-right: 38px;
            align-items: center;
            .title {
                @include sc(24px, #fff);
                @include fj(center);
                align-items: center;
                width: 179px;
                height: 60px;
                background: #b72e32;
            }
            .more {
                @include sc(24px, $--color-text-primary);
                font-weight: 500;
                line-height: 34px;
            }
        }
        .details-content {
            @include sc(24px, $--color-text-secondary);
            padding: 30px;
            line-height: 50px;
            overflow-x: hidden;
            img {
                max-width: 100%;
            }
        }
    }
    .clock-info {
        @include sc(16px, $--color-text-secondary);
        line-height: 24px;
        // width: 320px;
        overflow: hidden;
        .clock-label {
            line-height: 28px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            /*! autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            -webkit-line-clamp: 2;
            line-clamp: 2;
            word-break: break-all; /*追加这一行代码*/
        }
    }
    .clock-button {
        @include sc(16px, $--color-text-primary);
        border: 2px solid $--color-text-primary;
        padding: 4px 16px;
        display: inline-block;
        border-radius: 28px;
        line-height: 24px;
    }
}
</style>